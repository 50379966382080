import request from "../utils/request";

export function index(params = {}) {
  return request({
    url: "districts",
    method: "get",
    params,
  });
}
export function getAllDistrict(params = {}) {
  return request({
    url: "districts",
    method: "get",
    params,
  });
}

export function store(data) {
  return request({
    url: "/districts",
    method: "post",
    data,
  });
}
export function update(id, data) {
  return request({
    url: `/districts/${id}`,
    method: "put",
    data,
  });
}
export function destroy(id) {
  return request({
    url: `/districts/${id}`,
    method: "delete",
  });
}
