<template>
  <PageListLayout
    icon="mdi-map-marker-outline"
    title="DANH SÁCH TỈNH HUYỆN"
    subTitle="Danh sách tỉnh huyện quản lý"
    titleAdd="Tạo mới tỉnh huyện"
  >
    <template slot="filter">
      <div>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="formSearch.search"
          label="Tìm kiếm theo tên tỉnh"
          clearable
          single-line
          outlined
          dense
          hide-details
        ></v-text-field>
      </div>
    </template>
    <div class="d-flex flex-grow-0 mb-5 align-end">
      <div class="mr-5">
        <v-text-field
          v-model="form_province.name"
          label="Nhập tên tỉnh ..."
          dense
          hide-details
          filled
          style="width:350px"
        ></v-text-field>
      </div>
      <v-btn small color="primary" @click="onAddProvince()">
        <v-icon small color="white">mdi-plus</v-icon>
        Thêm tỉnh</v-btn
      >
    </div>
    <v-expansion-panels>
      <v-expansion-panel
        v-for="(item, i) in tableData"
        :key="`province_id${i}`"
      >
        <v-expansion-panel-header @click.prevent="onClickPanel(item)">
          <div style="font-weight: 500;">
            {{ item.name }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex align-center">
            <v-text-field
              v-model="item.name"
              label="Tên tỉnh"
              dense
              hide-details
              filled
              placeholder="Nhập tên tỉnh"
            >
            </v-text-field>

            <div class="d-flex align-center ml-auto">
              <v-btn fab small icon @click.stop.prevent="updateProvince(item)">
                <v-icon small color="primary">mdi-pencil</v-icon>
              </v-btn>
              <v-btn fab small icon @click.stop.prevent="deleteProvince(item)">
                <v-icon small color="red">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="my-3 d-flex align-center">
            <v-icon class="mr-2">mdi-table</v-icon>
            Quận huyện
          </div>
          <table>
            <tbody>
              <tr
                v-for="(tempt, index) in districts"
                :key="`districts${index}`"
              >
                <td>
                  <v-text-field
                    v-model="tempt.name"
                    label="Tên huyện"
                    dense
                    hide-details
                    filled
                    placeholder="Nhập tên huyện"
                    style="width:300px"
                  ></v-text-field>
                </td>
                <td>
                  <v-btn fab small icon @click.prevent="updateDistrict(tempt)">
                    <v-icon small color="primary">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn fab small icon @click.prevent="deleteDistrict(tempt)">
                    <v-icon small color="red">mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <div class="mt-5 d-flex align-end">
                  <div class="mr-2">
                    <v-text-field
                      v-model="form_district.name"
                      label="Tên huyện"
                      dense
                      hide-details
                      filled
                      placeholder="Nhập tên huyện ..."
                      style="width:300px"
                    ></v-text-field>
                  </div>
                  <v-btn small color="primary" @click="onAddDistrict(item.id)">
                    <v-icon small color="white">mdi-plus</v-icon>
                    Thêm huyện</v-btn
                  >
                </div>
              </tr>
            </tbody>
          </table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="my-4">
      <v-pagination
        v-model="pagination.page"
        :length="pagination.last_page"
        :total-visible="10"
      ></v-pagination>
    </div>
  </PageListLayout>
</template>
<script>
import * as DISTRICT from "@/api/district.api";
import * as PROVINCE from "@/api/province.api";
import { debounce } from "lodash";
export default {
  data() {
    return {
      page: 1,
      per_page: 15,
      btnLoading: false,
      loading: false,

      pagination: {
        page: 1,
        per_page: 10,
        last_page: 1,
        total: 0,
        current_page: 1,
      },
      formSearch: {
        search: "",
      },
      headers: [
        { text: "Tên tỉnh", value: "name" },
        {
          text: "Huyện",
          align: "start",
          sortable: false,
          value: "district.name",
        },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
      tableData: [],
      form_province: { name: null },
      form_district: { name: null },
      districts: [],
    };
  },
  computed: {},
  created() {
    this.getData({ order_by: ["name"] });
  },
  watch: {
    formSearch: {
      deep: true,
      handler: debounce(async function(val) {
        this.getData({ order_by: ["updated_at"] });
      }, 300),
    },
    "pagination.page": function(val) {
      this.page = val;
      this.getData({ order_by: ["name"] });
    },
  },
  methods: {
    async getData(params) {
      this.tableData = [];
      this.loading = true;
      let data = await PROVINCE.index({
        page: this.page,
        per_page: this.per_page,
        is_paginate: true,
        ...this.formSearch,
        ...params,
      });
      this.loading = false;
      this.tableData = data.data.data;
      this.pagination = Object.assign(this.pagination, { ...data.data });
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },

    async onAddProvince() {
      if (!this.form_province.name) {
        this.notifyError("Tên tỉnh là bắt buộc !");
        return;
      }
      const { data } = await PROVINCE.store(this.form_province);
      if (data) {
        this.getData({ order_by_desc: ["updated_at"] });
        this.form_province = this.$options.data.call(this).form_province;
        this.notifySuccess("Thêm mới tỉnh thành công");
      }
    },
    async updateProvince(item) {
      if (!item.name) {
        this.notifyError("Tên tỉnh là bắt buộc !");
        return;
      }
      const { data } = await PROVINCE.update(item.id, { ...item });
      if (data) {
        this.getData({ order_by_desc: ["updated_at"] });
        this.notifySuccess("Cập nhật tỉnh thành công");
      }
    },
    async deleteProvince(item) {
      this.$confirmBox.show({
        title: "Xóa tỉnh",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa tỉnh " +
          "<strong>" +
          item.name +
          " ?" +
          "</strong>",
        action: async () => await PROVINCE.destroy(item.id),
        onDone: async () =>
          await this.getData({ order_by_desc: ["updated_at"] }),
      });
    },

    async onAddDistrict(province_id) {
      if (!this.form_district.name) {
        this.notifyError("Tên huyện là bắt buộc !");
        return;
      }
      const { data } = await DISTRICT.store({
        ...this.form_district,
        province_id: province_id,
      });
      if (data) {
        this.getDistricts(province_id);
        this.form_district = this.$options.data.call(this).form_district;
        this.notifySuccess("Thêm mới huyện thành công !");
      }
    },
    async updateDistrict(item) {
      if (!item.name) {
        this.notifyError("Tên huyện là bắt buộc !");
        return;
      }
      const { data } = await DISTRICT.update(item.id, { ...item });
      if (data) {
        this.getDistricts(item.province_id);
        this.notifySuccess("Cập nhật huyện thành công !");
      }
    },
    async deleteDistrict(item) {
      this.$confirmBox.show({
        title: "Xóa huyện",
        width: 500,
        body:
          "Bạn có chắc chắn muốn xóa huyện !" +
          "<strong>" +
          item.name +
          " ?" +
          "</strong>",
        action: async () => await DISTRICT.destroy(item.id),
        onDone: async () => await this.getDistricts(item.province_id),
      });
    },
    async onClickPanel(province) {
      if (this.districts.length && province.id == this.districts[0].province_id)
        return;
      this.getDistricts(province.id);
    },
    async getDistricts(province_id) {
      const { data } = await DISTRICT.index({
        province_id: province_id,
        order_by: ["updated_at"],
      });
      this.districts = data;
    },
    notifySuccess(message = null) {
      this.$toast.success(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    notifyError(message = null) {
      this.$toast.error(message, {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
};
</script>
