import request from "../utils/request";

export function index(params = {}) {
  return request({
    url: "provinces",
    method: "get",
    params,
  });
}
export function getAllProvince(params = {}) {
  return request({
    url: "provinces",
    method: "get",
    params,
  });
}

export function store(data) {
  return request({
    url: "/provinces",
    method: "post",
    data,
  });
}
export function update(id, data) {
  return request({
    url: `/provinces/${id}`,
    method: "put",
    data,
  });
}
export function destroy(id) {
  return request({
    url: `/provinces/${id}`,
    method: "delete",
  });
}
